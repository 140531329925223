.App {
	height: 100vh;
	display: flex;
	flex-direction: column;

	&:before {
		content: "";
		position: fixed;
		background: #03A9F4;
		opacity: 0;
		pointer-events: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		transition: opacity .55s;
	}

	&:after {
		content: "Xposed's TV";
		font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-weight: 100;
		color: white;
		font-size: 100px;
		position: fixed;
		z-index: 100;
		opacity: 0;
		text-shadow: 0 0 100px rgba(0,0,0,.5);
		left: 50%;
		top: 50%;
		transform: translate(-50%,-100%);
		transition: opacity .55s, transform .75s cubic-bezier(.55,0,.1,1);
		pointer-events: none;
	}

	&.loading {
		&:before, &:after {
			opacity: 1;
		}

		&:after {
			transform: translate(-50%,-50%);
		}
	}
}

.VideoPlayer {
	background: #212121;
	flex: 1;
	width: 100% !important;
	position: relative;

	div, iframe {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100% !important;
	}

	& > div > iframe, & > div > div {
		// Inactive state
		// pointer-events: none;
		// opacity: 0;
	}

	div.active, iframe.active {
		opacity: 1;
		pointer-events: all;
		z-index: 5;
	}

	#streamablePlayer > div {
		padding-bottom: 0 !important;
		height: 100% !important;
		position: absolute !important;
	}
}

.Header {
	height: 50px;
	color: #fff;
	font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 400;
	background: #03A9F4;
	padding: 0 50px 0 20px;
	line-height: 50px;
	text-align: justify;
	display: flex;
	justify-content: space-between;

	& > span, ul {
		display: inline-block;
		vertical-align: top;
	}

	span {
		// max-width: 75vw;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 18px;
	}

	ul.links {
		margin: 0;
		padding: 0;
		white-space: nowrap;
		margin-left: 10px;

		li {
			display: inline-block;
			margin: 0 5px;
			font-size: 14px;

			a {
				cursor: pointer;
				color: white;
				text-decoration: none;
				transition: .3s;

				&:hover {
					opacity: .8;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.cols {
		width: 50%;
		display: table-cell;
		text-align: left;
		vertical-align: top;

		&:first-child {
			border-right: 1px solid #ccc;
			padding-right: 20px;
		}

		&:last-child {
			padding-left: 20px;
		}
	}

	.options {
		font-size: 0;
		display: table;
		position: absolute;
		right: 8px;
		top: 75px;
		background: #f0f0f0;
		padding: 20px;
		z-index: 10;
		border-radius: 8px;
		pointer-events: none;
		opacity: 0;
		transform: translateY(-50px);
		transition: opacity .8s, transform .7s cubic-bezier(.55,0,.1,1);

		// border-top-right-radius: 0;
		&:before {
			content: "";
			position: absolute;
			top: -8px;
			right: 11px;
			height: 20px;
			width: 20px;
			border-radius: 3px;
			transform: rotate(45deg);
			background: #f0f0f0;
		}

		ul {
			margin: 0;
			padding: 0;
			line-height: 40px;
			width: 100%;
		}

		h3 {
			color: #1a1a1a;
			font-weight: 400;
			font-size: 16px;
			border-bottom: 1px solid #ddd;
			padding-bottom: 10px;
			margin-bottom: 0;
			margin-top: 0;
			line-height: normal;
		}

		li {
			display: block;
			position: relative;

			&:last-child {
				margin-bottom: 20px;
			}

			a {
				width: 100%;
				display: block;
				color: #aaa;
				transition: color .25s;

				&:hover, &:focus, &.active {
					color: #03A9F4;
				}
				text-transform: uppercase;
				text-decoration: none;
				font-size: 12px;
				font-weight: 400;
				border-bottom: 1px solid #ccc;
			}

			.delete {
				position: absolute;
				right: 0;
				top: 9px;
				width: 20px;
				height: 20px;
				line-height: 20px;
				border: none;
				text-align: center;
				cursor: pointer;
				font-weight: 700;
				opacity: 0;
				transition: .25s;

				&:hover {
					color: #03A9F4;
				}
			}

			&:hover {
				.delete {
					opacity: 1;
				}
			}
		}

		#custom li:last-child {
			margin-bottom: 40px;
		}

		input[type="text"] {
			cursor: pointer;
			display: block;
			margin-top: -40px;
			appearance: none;
			border: none;
			width: 100%;
			color: #aaa;
			background: transparent;
			height: 41px;
			font-size: 12px;
			font-weight: 400;
			position: relative;
			z-index: 10;
			text-transform: uppercase;
		}

		section {
			border-bottom: 1px solid #ddd;
			margin-bottom: 7px;

			&:last-child {
				margin-bottom: 0;
				border: none;
			}
		}

		label {
			color: #bbb;
			font-size: 13px;
			line-height: 16px;
		}

		.notes {
			color: #bbb;
			font-size: 13px;
			line-height: 16px;
			display: block;

			.shortcuts {
				display: block;
				margin: 0;
				margin-top: 10px;
				padding: 0;
				font-size: 13px;
				line-height: 16px;

				li {
					margin-bottom: 10px;
				}
			}

			.key {
				background: #ddd;
				color: #1a1a1a;
				padding: 2px 10px 4px;
				border-radius: 4px;
				margin-right: 5px;
				text-transform: uppercase;
				font-size: 12px;
				border: 1px solid #ccc;
				vertical-align: middle;
				display: inline-block;
			}
		}
	}
}

.toggleOptions {
	position: absolute;
	width: 25px;
	height: 18px;
	top: 17px;
	right: 17px;
	line-height: normal;
	text-align: center;

	div {
		display: inline-block;
		line-height: normal;
		top: 0;

		&, &:after, &:before {
			position: absolute;
			transition: .3s cubic-bezier(.55,0,.1,1);
			height: 3px;
			width: 4px;
			background-color: white;
			border-radius: 3px;
			left: 50%;
			transform: translateX(-50%);
		}

		&:after, &:before {
			content: "";
		}

		&:before {
			top: 7px;
			transition-delay: .15s;
		}

		&:after {
			top: 14px;
			transition-delay: .25s;
		}
	}

	&:hover {
		cursor: pointer;

		div {
			&, &:before, &:after {
				width: 12px;
			}
		}
	}

	&--open {
		div {
			&, &:before, &:after {
				width: 100% !important;
			}
		}

		& + .options {
			transform: translate(0);
			opacity: 1;
			pointer-events: all;
		}
	}
}

// #toggleOptions {
// 	position: absolute;
// 	left: -9999px;

// 	&:checked + label {
// 		div {
// 			&, &:before, &:after {
// 				width: 100%;
// 			}
// 		}

// 		& + .options {
// 			transform: translate(0);
// 			opacity: 1;
// 			pointer-events: all;
// 		}
// 	}
// }

#__bs_notify {
	top: 400px;
}
