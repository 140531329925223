html{
    box-sizing:border-box;
}
*,*:before,*:after{
    box-sizing:inherit;
}

html,body{
    height:100%;
}
body{
    background: #4e4e4e;
    margin:0;
    &:-webkit-full-screen {
        width: 100%;
        height: 100%;
    }
}

.wrapper{
    width:100%;
    height:calc(100% - 123px);
    overflow: hidden;
}

