body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

html, body {
  height: 100%; }

body {
  background: #4e4e4e;
  margin: 0; }
  body:-webkit-full-screen {
    width: 100%;
    height: 100%; }

.wrapper {
  width: 100%;
  height: calc(100% - 123px);
  overflow: hidden; }

.App {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .App:before {
    content: "";
    position: fixed;
    background: #03A9F4;
    opacity: 0;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    -webkit-transition: opacity .55s;
    transition: opacity .55s; }
  .App:after {
    content: "Xposed's TV";
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 100;
    color: white;
    font-size: 100px;
    position: fixed;
    z-index: 100;
    opacity: 0;
    text-shadow: 0 0 100px rgba(0, 0, 0, 0.5);
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    -webkit-transition: opacity 0.55s, -webkit-transform 0.75s cubic-bezier(0.55, 0, 0.1, 1);
    transition: opacity 0.55s, -webkit-transform 0.75s cubic-bezier(0.55, 0, 0.1, 1);
    transition: opacity 0.55s, transform 0.75s cubic-bezier(0.55, 0, 0.1, 1);
    transition: opacity 0.55s, transform 0.75s cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 0.75s cubic-bezier(0.55, 0, 0.1, 1);
    pointer-events: none; }
  .App.loading:before, .App.loading:after {
    opacity: 1; }
  .App.loading:after {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.VideoPlayer {
  background: #212121;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100% !important;
  position: relative; }
  .VideoPlayer div, .VideoPlayer iframe {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100% !important; }
  .VideoPlayer div.active, .VideoPlayer iframe.active {
    opacity: 1;
    pointer-events: all;
    z-index: 5; }
  .VideoPlayer #streamablePlayer > div {
    padding-bottom: 0 !important;
    height: 100% !important;
    position: absolute !important; }

.Header {
  height: 50px;
  color: #fff;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  background: #03A9F4;
  padding: 0 50px 0 20px;
  line-height: 50px;
  text-align: justify;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .Header > span, .Header ul {
    display: inline-block;
    vertical-align: top; }
  .Header span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px; }
  .Header ul.links {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    margin-left: 10px; }
    .Header ul.links li {
      display: inline-block;
      margin: 0 5px;
      font-size: 14px; }
      .Header ul.links li a {
        cursor: pointer;
        color: white;
        text-decoration: none;
        -webkit-transition: .3s;
        transition: .3s; }
        .Header ul.links li a:hover {
          opacity: .8; }
      .Header ul.links li:last-child {
        margin-right: 0; }
  .Header .cols {
    width: 50%;
    display: table-cell;
    text-align: left;
    vertical-align: top; }
    .Header .cols:first-child {
      border-right: 1px solid #ccc;
      padding-right: 20px; }
    .Header .cols:last-child {
      padding-left: 20px; }
  .Header .options {
    font-size: 0;
    display: table;
    position: absolute;
    right: 8px;
    top: 75px;
    background: #f0f0f0;
    padding: 20px;
    z-index: 10;
    border-radius: 8px;
    pointer-events: none;
    opacity: 0;
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transition: opacity 0.8s, -webkit-transform 0.7s cubic-bezier(0.55, 0, 0.1, 1);
    transition: opacity 0.8s, -webkit-transform 0.7s cubic-bezier(0.55, 0, 0.1, 1);
    transition: opacity 0.8s, transform 0.7s cubic-bezier(0.55, 0, 0.1, 1);
    transition: opacity 0.8s, transform 0.7s cubic-bezier(0.55, 0, 0.1, 1), -webkit-transform 0.7s cubic-bezier(0.55, 0, 0.1, 1); }
    .Header .options:before {
      content: "";
      position: absolute;
      top: -8px;
      right: 11px;
      height: 20px;
      width: 20px;
      border-radius: 3px;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      background: #f0f0f0; }
    .Header .options ul {
      margin: 0;
      padding: 0;
      line-height: 40px;
      width: 100%; }
    .Header .options h3 {
      color: #1a1a1a;
      font-weight: 400;
      font-size: 16px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      margin-bottom: 0;
      margin-top: 0;
      line-height: normal; }
    .Header .options li {
      display: block;
      position: relative; }
      .Header .options li:last-child {
        margin-bottom: 20px; }
      .Header .options li a {
        width: 100%;
        display: block;
        color: #aaa;
        -webkit-transition: color .25s;
        transition: color .25s;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 12px;
        font-weight: 400;
        border-bottom: 1px solid #ccc; }
        .Header .options li a:hover, .Header .options li a:focus, .Header .options li a.active {
          color: #03A9F4; }
      .Header .options li .delete {
        position: absolute;
        right: 0;
        top: 9px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border: none;
        text-align: center;
        cursor: pointer;
        font-weight: 700;
        opacity: 0;
        -webkit-transition: .25s;
        transition: .25s; }
        .Header .options li .delete:hover {
          color: #03A9F4; }
      .Header .options li:hover .delete {
        opacity: 1; }
    .Header .options #custom li:last-child {
      margin-bottom: 40px; }
    .Header .options input[type="text"] {
      cursor: pointer;
      display: block;
      margin-top: -40px;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      border: none;
      width: 100%;
      color: #aaa;
      background: transparent;
      height: 41px;
      font-size: 12px;
      font-weight: 400;
      position: relative;
      z-index: 10;
      text-transform: uppercase; }
    .Header .options section {
      border-bottom: 1px solid #ddd;
      margin-bottom: 7px; }
      .Header .options section:last-child {
        margin-bottom: 0;
        border: none; }
    .Header .options label {
      color: #bbb;
      font-size: 13px;
      line-height: 16px; }
    .Header .options .notes {
      color: #bbb;
      font-size: 13px;
      line-height: 16px;
      display: block; }
      .Header .options .notes .shortcuts {
        display: block;
        margin: 0;
        margin-top: 10px;
        padding: 0;
        font-size: 13px;
        line-height: 16px; }
        .Header .options .notes .shortcuts li {
          margin-bottom: 10px; }
      .Header .options .notes .key {
        background: #ddd;
        color: #1a1a1a;
        padding: 2px 10px 4px;
        border-radius: 4px;
        margin-right: 5px;
        text-transform: uppercase;
        font-size: 12px;
        border: 1px solid #ccc;
        vertical-align: middle;
        display: inline-block; }

.toggleOptions {
  position: absolute;
  width: 25px;
  height: 18px;
  top: 17px;
  right: 17px;
  line-height: normal;
  text-align: center; }
  .toggleOptions div {
    display: inline-block;
    line-height: normal;
    top: 0; }
    .toggleOptions div, .toggleOptions div:after, .toggleOptions div:before {
      position: absolute;
      -webkit-transition: 0.3s cubic-bezier(0.55, 0, 0.1, 1);
      transition: 0.3s cubic-bezier(0.55, 0, 0.1, 1);
      height: 3px;
      width: 4px;
      background-color: white;
      border-radius: 3px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    .toggleOptions div:after, .toggleOptions div:before {
      content: ""; }
    .toggleOptions div:before {
      top: 7px;
      -webkit-transition-delay: .15s;
              transition-delay: .15s; }
    .toggleOptions div:after {
      top: 14px;
      -webkit-transition-delay: .25s;
              transition-delay: .25s; }
  .toggleOptions:hover {
    cursor: pointer; }
    .toggleOptions:hover div, .toggleOptions:hover div:before, .toggleOptions:hover div:after {
      width: 12px; }
  .toggleOptions--open div, .toggleOptions--open div:before, .toggleOptions--open div:after {
    width: 100% !important; }
  .toggleOptions--open + .options {
    -webkit-transform: translate(0);
            transform: translate(0);
    opacity: 1;
    pointer-events: all; }

#__bs_notify {
  top: 400px; }

.VideoList {
  border-top: 3px solid #03A9F4;
  overflow: auto; }
  .VideoList ul {
    padding: 0;
    background: #212121;
    white-space: nowrap;
    display: table-row; }
    .VideoList ul li {
      vertical-align: top;
      margin: 0 5px;
      display: inline-block; }
      .VideoList ul li a {
        display: block;
        cursor: pointer;
        padding: 10px 5px; }
        .VideoList ul li a, .VideoList ul li a img {
          -webkit-transition: .25s;
          transition: .25s; }
        .VideoList ul li a img {
          opacity: .5;
          height: 81px; }
        .VideoList ul li a:hover img {
          opacity: .7; }
        .VideoList ul li a.active {
          background: #727272; }
          .VideoList ul li a.active img {
            opacity: 1; }
        .VideoList ul li a.nsfw {
          position: relative; }
          .VideoList ul li a.nsfw:before {
            content: "";
            position: absolute;
            background: rgba(0, 0, 0, 0.8);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1; }
          .VideoList ul li a.nsfw:hover:before {
            -webkit-transition: 15s;
            transition: 15s;
            background: rgba(0, 0, 0, 0); }

