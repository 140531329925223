.VideoList {
	border-top: 3px solid #03A9F4;
	overflow: auto;

	ul {
		padding: 0;
		background: #212121;
		white-space: nowrap;
		display: table-row;

		li {
			vertical-align: top;

			a {
				display: block;
				cursor: pointer;
				padding: 10px 5px;

				&, img {
					transition: .25s;
				}

				img {
					opacity: .5;
					height: 81px;
				}

				&:hover {
					img {
						opacity: .7;
					}
				}

				&.active {
					img {
						opacity: 1;
					}
					background: #727272;
				}

				&.nsfw {
					position: relative;

					&:before {
						content: "";
						position: absolute;
						background: rgba(0,0,0,.8);
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 1;
					}

					&:hover:before {
						transition: 15s;
						background: rgba(0,0,0,0);
					}
				}
			}
			margin: 0 5px;
			display: inline-block;
		}
	}
}